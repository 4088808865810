import React from "react"
import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles
} from "@material-ui/core"
import {buildImageObj} from './helpers'
import {imageUrlFor} from './image-url'
import BlockContent from "@sanity/block-content-to-react"
import Zoom from "react-reveal/Zoom"
import ListIcon from "@material-ui/icons/Star"
const useStyles=makeStyles(theme=>({
  text:{
    display:"flex",
    flexDirection:"column",
    justifyContent:"space-evenly",
    alignItems:"start",
    height:"100%",
  },
  inlineImage:{
    display:"inline-block",
    marginRight:"1.5rem",
    padding:"0.5rem 0",
    maxWidth:"500px",
    maxHeight:'500px',
    float:"left",

  [theme.breakpoints.down('xs')]:{
    width:"100%",
    padding:theme.spacing(1,0),
    margin:0
  }
},
blockImage:{
  display:'inline-block',
  width:"100%",
  height:"100%",

}
}))

export default function TextBlock({ text="" }) {
  const classes=useStyles()
  const serializers = {
    list: props => {
      return (
        <Zoom>
          <List>{props.children}</List>
        </Zoom>
      )
    },
    listItem: props => {
      return (
        <ListItem>
          <ListItemIcon>
            <ListIcon color="primary" />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="h5" component="span" color="inherit">{props.children}</Typography>
          </ListItemText>
        </ListItem>
      )
    },
    types: {
      figure: props=>{
        return(
         <img className={classes.blockImage} src={props.node.asset.url}/>
        )
      },
      code: props => (
        <pre data-language={props.node.language}>
          <code>{props.node.code}</code>
        </pre>
      ),
      span: props => (
        <>
          <Typography variant="h1">{props.children}</Typography>
        </>
      ),
      block: props => {
        const { style = "normal" } = props.node
        
        if (/^h\d/.test(style)) {
          switch (style) {
            case "h1":
              return <Typography variant="h1"> {props.children}</Typography>
            case "h2":
              return <Typography variant="h2"> {props.children}</Typography>
            case "h3":
              return <Typography variant="h3"> {props.children}</Typography>
            case "h4":
              return <Typography variant="h4"> {props.children}</Typography>
            case "h5":
              return <Typography variant="h5"> {props.children}</Typography>
            case "h6":
              return <Typography variant="h6"> {props.children}</Typography>
            default: return
          }
        }

        if (style === "blockquote") {
          return <Typography variant="h6" component="blockquote" >{props.children}</Typography>
        }
        if (style === "normal") {
          return (
            <Typography variant="h5" component="p" style={{whiteSpace:"pre-wrap"}}>
             {props.children}
            </Typography>
          )
        }
        // Fall back to default handling
        return BlockContent.defaultSerializers.types.block(props)
      },
    },

    marks: {
      color: props => (
      <span style={{ color: props.mark.hex }}>{props.children}</span>
      ),
      inlineImage: props=>{
        let url=imageUrlFor(buildImageObj(props.mark)).width(500).url()
        return(
          <img className={classes.inlineImage} src={url}/>
        )
      },
      alignCenter: props=>(
        <p style={{textAlign:"center", width:"100vw"}}>{props.children}</p>
      ),
      alignLeft: props=>(
        <p style={{textAlign:"left", width:"100vw"}}>{props.children}</p>
      ),
      alignRight: props=>(
        <p style={{textAlign:"right", width:"100vw"}}>{props.children}</p>
      )
    }
  }
  return <BlockContent blocks={text} serializers={serializers} />
}
