import React from "react"
import makeCarousel from "react-reveal/makeCarousel"
import { makeStyles } from "@material-ui/styles"
import ArrowLeft from "@material-ui/icons/ArrowBackIos"
import ArrowRight from "@material-ui/icons/ArrowForwardIos"
import { IconButton } from "@material-ui/core"

export default function Carousel(props) {
  const useStyles = makeStyles(theme => ({
    container: {
      position: "relative",
      height: props.height?props.height:"50vh",
      minHeight:"500px",
      width: "100%",
      [theme.breakpoints.down('xs')]:{
        minHeight:"90vh"
      }

    },
    arrowLeft: {
      display:props.arrows?"block":"none",
      zIndex: "100",
      textAlign: "center",
      position: "absolute",
      top: "50%",
      fontSize: "1rem",
      cursor: "pointer",
      userSelect: "none",
      border:"none",
      left: -10,
      color:theme.palette.primary.main,
      [theme.breakpoints.down('xs')]:{
        display:"none"
      }
    },
    arrowRight: {
        display:props.arrows?"block":"none",
        zIndex: "100",
        position: "absolute",
        top: "50%",
        fontSize: "1rem",
        cursor: "pointer",
        userSelect: "none",
        border:"none",
        right: -10,
        color:theme.palette.primary.main,
        [theme.breakpoints.down('xs')]:{
          display:"none"
        }
    },
    children:{
      height:"100%",
      width:"100%"
    },
    dots: {
      textAlign: "center",
      width: "100%",
      zIndex: "100",
      position: "absolute",
      bottom: "0",
      display: props.dots?"flex":"none",
      justifyContent: "center",
    },
    dot: {
      fontSize: "1.5rem",
      cursor: "pointer",
      userSelect: "none",
      borderColor:"transparent",
      backgroundColor:"transparent",
      color:theme.palette.primary.main
    },
  }))
  const classes = useStyles()

  const CarouselUI = ({ position, total, handleClick, children }) => (
    <div className={classes.container}>
      <div className={classes.children}>
      {children}
      <IconButton
        onClick={handleClick}
        data-position={position - 1}
        className={classes.arrowLeft}
      >
        <ArrowLeft/>
      </IconButton>
      <IconButton
        onClick={handleClick}
        data-position={position + 1}
        className={classes.arrowRight}
      >
        <ArrowRight/>
      </IconButton>
      <span className={classes.dots}>
        {Array(...Array(total)).map((val, index) => (
          <button
            key={index}
            onClick={handleClick}
            data-position={index}
            className={classes.dot}

          >
            {index === position ? "● " : "○ "}
          </button>
        ))}
      </span></div>
    </div>
  )
  const Slider = makeCarousel(CarouselUI)
  return <Slider defaultWait={12000} >{props.children}</Slider>
}
