import React, { useState } from "react"
import Carousel, { Modal, ModalGateway } from "react-images"
import Image from "gatsby-image"
import { chunk, sum } from "../../../lib/helpers"
import {
  Typography,
  Container,
  Grid,
  useMediaQuery,
  Box,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

export default function Gallery({ data, itemsPerRowByBreakpoints = [4] }) {
  const fullSizeImages = []
  const caroStyles={
    view:(base)=>({...base,'&>img':{ maxHeight:"100vh"}})}

  const phone = useMediaQuery("(max-width:500px)")
  const tablet = useMediaQuery("(max-width:1024px)")
  if (phone) {
    itemsPerRowByBreakpoints = [1]
  } else if (tablet) {
    itemsPerRowByBreakpoints = [2]
  }
  const useStyles = makeStyles(theme => ({
    galleryContainer: {
      width: "100%",
      display: "grid",
      gridTemplateColumns: "1fr",
      gridGap: "2rem",
      justifySelf: "center",
    },
    grid:{
      justifyContent:"center",
      [theme.breakpoints.between('xs','sm')]:{
        justifyContent:"left"
      }
    },
    caption: {
      position: "absolute",
      bottom: "90px",
      transition: "transform 0.01s",
    },
    thumbnail: {
      position: "relative",
      cursor: "pointer",
      userSelect: "none",
      height: "100%",
      width:"100%",
      "& img": {
        transition: "all 0.5s ease-in-out !important",
      },
      "&:hover": {
        "& img": {
          transform: "scale3d(1.2,1.2,1.2)",
        },
      },
    },
    textBG: {
      width: "100%",
      height: "50px",
      position: "relative",
      display: "block",
      backgroundColor: [theme.palette.background.custom],
    },
  }))
  const classes = useStyles()
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)
  const openLightbox = (index) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }
  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }
  data.fullWidth.map(image => {
    fullSizeImages.push({
      source: image.asset.fluid.src,
    })
    return null
  })
  const aspectRatios = data.thumbnails.map(
    image => image.asset.fluid.aspectRatio
  )
  const rowAspectRatioSumsByBreakpoints = itemsPerRowByBreakpoints.map(
    itemsPerRow =>
      chunk(aspectRatios, itemsPerRow).map(rowAspectRatios =>
        sum(rowAspectRatios)
      )
  )

  return (
    <Container maxWidth="xl">
      <Box mt={phone?6:12} mb={phone?6:12}>
        <Grid container spacing={3} justify="center">
          <Grid item xs={12}>
            <Typography align="center" variant="h2" color={data.headingcolor?data.headingcolor:"primary"}>
              {data.title}
            </Typography>
            <Typography align="center" variant="h4" color={data.headingcolor?data.headingcolor:"primary"}>
              {data.description}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} className={classes.grid}>
              {data.thumbnails.map((image, i) => (
                <Grid
                key={i}
                  item
                  style={{
                    width:itemsPerRowByBreakpoints[0]===1?"100%":itemsPerRowByBreakpoints[0]===2?"50%": rowAspectRatioSumsByBreakpoints.map((r, j) => {
                      const ri = Math.floor(i / itemsPerRowByBreakpoints[0])
                      const ra = r[ri]

                      if(Math.ceil(data.thumbnails.length/itemsPerRowByBreakpoints[0])===ri+1&&data.thumbnails.length%itemsPerRowByBreakpoints[0]!==0){
                      return `${((image.asset.fluid.aspectRatio / ra/itemsPerRowByBreakpoints[0])*Math.floor(data.thumbnails.length%itemsPerRowByBreakpoints[0])) * 100}%`
                      }
                      else
                      {
                      return `${(image.asset.fluid.aspectRatio / ra) * 100}%`
                      }
                    }),
                  }}
                >
                  <div
                    className={classes.thumbnail}
                    role="button"
                    tabIndex={i}
                    onKeyDown={()=>openLightbox(i)}
                    onClick={()=>openLightbox(i)}
                  >
                    <Image
                      fluid={ image.asset.fluid}
                      alt={image.alt}
                      style={{ height: "100%" }}
                    />
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel currentIndex={currentImage} views={fullSizeImages} styles={caroStyles} />
            </Modal>
          ) : null}
        </ModalGateway>
      </Box>
    </Container>
  )
}
