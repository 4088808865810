import React from "react"
import { Typography, Grid, Box, Avatar } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import Image from 'gatsby-plugin-sanity-image'
import TextBlock from "../../../lib/textblock"

const useStyles = makeStyles(theme => ({
  quote: {
    content: "open-quote",
    fontSize: "5rem",
    color: "goldenrod",
    gridRow: "1/2",
    marginBottom: "-2rem",
  },
  grid:{
    display:"grid",
    gridTemplateColumns:'min-content 1fr',
    alignContent:"center",
    alignItems:"center",
    gridGap:"10px"
  },
  margins: {
    padding: theme.spacing(5, 10, 5, 10),
    height: "fit-content",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(5,2),
    },
  },
  avatar: {
    width: "100px",
    height: "100px",
    [theme.breakpoints.down("xs")]: {
      width: "70px",
      height: "70px",
    },
  },
}))
export default function Testimonial({ data,image }) {
  const classes = useStyles()
  return (
    <Box className={classes.margins}>

      <Grid container className={classes.textbg} spacing={3}>
        <Grid item xs={12}>
          <TextBlock text={data.testimonialText} />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.grid}>
            <div className={classes.logo}>
              <Avatar className={classes.avatar}>
                <Image
                  {...image}
                  css={{
                    width:'100%'
                  }}
                />
              </Avatar>
            </div>
            <div className={classes.position}>
              <Typography variant="h6">{data.writerName}</Typography>
              <Typography variant="h6">{data.writerPosition}</Typography>
            </div>
          </div>
        </Grid>

        <Grid item></Grid>
      </Grid>
    </Box>
  )
}
