import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import Typography from "@material-ui/core/Typography"
import TextBlock from "../../../lib/textblock"
import { Button, Grid } from "@material-ui/core"
import getFluidImage from "../../../lib/sanitygatsbyimage"
import Image from 'gatsby-image'
const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2),
  },
  container:{
    height:"100%",
  },

}))

export default function ProductCard({ data, image, buttons,actions }) {
  const classes = useStyles()
  const fluid=image?getFluidImage(data.image,image.asset.fluid):null
  const sendEnquiry=(product)=>{
    actions.values(prevstate=>({...prevstate,product:product}))

    actions.onshow(true)
  }
  return (
    <Card elevation={6} variant="elevation" className={classes.container}>
      <Grid container className={classes.container} alignContent="stretch" spacing={0}>
        <Grid item xs={12}>
          <Grid
            container
            alignItems="center"
            justify="space-between"
            className={classes.header}
          >
            <Grid item>
              <Typography variant="h4">{data.productName}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">{data.shortDescription}</Typography>
              <Typography variant="body1">${data.price}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {fluid&&
          <Image fluid={fluid} alt={image.asset.alt}/>
          }
        </Grid>
        <Grid item xs={12}>
          <TextBlock text={data.longDescription} />
        </Grid>
        <Grid item xs={12} style={{alignSelf:"flex-end"}}>
          <Grid container justify="space-between">
            {buttons &&
              buttons.map((button, index) => (
                <Grid key={`product${index}`} item xs={12 / buttons.length}>
                  <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    color="primary"
                    onClick={()=>sendEnquiry(data.productName)}
                  >
                   Enquire about {data.productName}
                  </Button>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}
