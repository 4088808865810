import React from "react"
import {
  Typography,
  Container,
  Box,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import Image from "gatsby-plugin-sanity-image"
import TextBlock from "../../../lib/textblock"
import { Cta } from "../blocks"


export default function TextOnImage({ data,image,imageMobile }) {
  const theme = useTheme()
  const phoone = useMediaQuery(theme.breakpoints.down("xs"))
  const mobileSettings=data.settings?data.settings.mobileProps?data.settings.mobileProps:null:null
  const desktopSettings=data.settings?data.settings.desktopProps?data.settings.desktopProps:null:null
  const desktop={
    top:desktopSettings?desktopSettings.desktopPositionTop?`${desktopSettings.desktopPositionTop}%`:0:0,
    bottom:desktopSettings?desktopSettings.desktopPositionBottom?`${desktopSettings.desktopPositionBottom}%`:0:0,
    left:desktopSettings?desktopSettings.desktopPositionLeft?`${desktopSettings.desktopPositionLeft}%`:0:0,
    right:desktopSettings?desktopSettings.desktopPositionRight?`${desktopSettings.desktopPositionRight}%`:0:0,
    paddingTop:desktopSettings?desktopSettings.desktopPaddingTop?`${desktopSettings.desktopPaddingTop}px`:0:0,
    paddingBottom:desktopSettings?desktopSettings.desktopPaddingBottom?`${desktopSettings.desktopPaddingBottom}px`:0:0,
    paddingLeft:desktopSettings?desktopSettings.desktopPaddingLeft?`${desktopSettings.desktopPaddingLeft}px`:0:0,
    paddingRight:desktopSettings?desktopSettings.desktopPaddingRight?`${desktopSettings.desktopPaddingRight}px`:0:0,
    marginTop:desktopSettings?desktopSettings.desktopMarginTop?`${desktopSettings.desktopMarginTop}px`:0:0,
    marginBottom:desktopSettings?desktopSettings.desktopMarginBottom?`${desktopSettings.desktopMarginBottom}px`:0:0,
    marginLeft:desktopSettings?desktopSettings.desktopMarginLeft?`${desktopSettings.desktopMarginLeft}px`:0:0,
    marginRight:desktopSettings?desktopSettings.desktopMarginRight?`${desktopSettings.desktopMarginRight}px`:0:0,
    bgColor:desktopSettings?desktopSettings.desktopBGColor?`rgba(${desktopSettings.desktopBGColor.rgb.r},${desktopSettings.desktopBGColor.rgb.g},${desktopSettings.desktopBGColor.rgb.b},${desktopSettings.desktopBGColor.alpha})`:'none':"none",
    display:desktopSettings?desktopSettings.bgFullWidthDesktop?"block":"none":"none"
  }
  const mobile={
    top:mobileSettings?mobileSettings.mobilePositionTop?`${mobileSettings.mobilePositionTop}%`:0:0,
    bottom:mobileSettings?mobileSettings.mobilePositionBottom?`${mobileSettings.mobilePositionBottom}%`:0:0,
    left:mobileSettings?mobileSettings.mobilePositionLeft?`${mobileSettings.mobilePositionLeft}%`:0:0,
    right:mobileSettings?mobileSettings.mobilePositionRight?`${mobileSettings.mobilePositionRight}%`:0:0,
    paddingTop:mobileSettings?mobileSettings.mobilePaddingTop?`${mobileSettings.mobilePaddingTop}px`:0:0,
    paddingBottom:mobileSettings?mobileSettings.mobilePaddingBottom?`${mobileSettings.mobilePaddingBottom}px`:0:0,
    paddingLeft:mobileSettings?mobileSettings.mobilePaddingLeft?`${mobileSettings.mobilePaddingLeft}px`:0:0,
    paddingRight:mobileSettings?mobileSettings.mobilePaddingRight?`${mobileSettings.mobilePaddingRight}px`:0:0,
    marginTop:mobileSettings?mobileSettings.mobileMarginTop?`${mobileSettings.mobileMarginTop}px`:0:0,
    marginBottom:mobileSettings?mobileSettings.mobileMarginBottom?`${mobileSettings.mobileMarginBottom}px`:0:0,
    marginLeft:mobileSettings?mobileSettings.mobileMarginLeft?`${mobileSettings.mobileMarginLeft}px`:0:0,
    marginRight:mobileSettings?mobileSettings.mobileMarginRight?`${mobileSettings.mobileMarginRight}px`:0:0,
    bgColor:mobileSettings?mobileSettings.mobileBGColor?`rgba(${mobileSettings.mobileBGColor.rgb.r},${mobileSettings.mobileBGColor.rgb.g},${mobileSettings.mobileBGColor.rgb.b},${mobileSettings.mobileBGColor.alpha})`:'none':"none",
    display:mobileSettings?mobileSettings.bgFullWidthDesktop?"block":"none":"none"
  }


  const useStyles = makeStyles(theme => ({
    header:{
      padding:theme.spacing(0,20,6,20),
      textAlign:"center",
      [theme.breakpoints.down('xs')]:{
        padding:theme.spacing(0,0,6,0),
      }
    },
    text: {
      position: "absolute",
      top:desktop.top,
      left:desktop.left,
      right:desktop.right,
      bottom:desktop.bottom,
      padding:`${desktop.paddingTop} ${desktop.paddingRight} ${desktop.paddingBottom} ${desktop.paddingLeft}`,
      margin:`${desktop.marginTop} ${desktop.marginRight} ${desktop.marginBottom} ${desktop.marginLeft}`,
      backgroundColor:desktop.bgColor,
      [theme.breakpoints.down("sm")]:{
      top:mobile.top,
      left:mobile.left,
      right:mobile.right,
      bottom:mobile.bottom,
      padding:`${mobile.paddingTop} ${mobile.paddingRight} ${mobile.paddingBottom} ${mobile.paddingLeft}`,
      margin:`${mobile.marginTop} ${mobile.marginRight} ${mobile.marginBottom} ${mobile.marginLeft}`,
      backgroundColor:mobile.bgColor
      },
      '&>div':{
        display:"flex",
        flexDirection:"column",
        flexWrap:"nowrap",
        justifyContent:"space-evenly",
        alignItems:"center",
        height:"100%",
        width:"100%",
        textAlign:"center"
      }
    },
    button: {
     position:"absolute",
     top:desktopSettings?desktopSettings.buttonPositionTop?`${desktopSettings.buttonPositionTop}%`:0:0,
     left:desktopSettings?desktopSettings.buttonPositionLeft?`${desktopSettings.buttonPositionLeft}%`:0:0,
     right:desktopSettings?desktopSettings.buttonPositionRight?`${desktopSettings.buttonPositionRight}%`:0:0,
     bottom:desktopSettings?desktopSettings.buttonPositionBottom?`${desktopSettings.buttonPositionBottom}%`:0:0,
     [theme.breakpoints.down('lg')]:{
      top:mobileSettings?mobileSettings.buttonPositionTop?`${mobileSettings.buttonPositionTop}%`:0:0,
    left:mobileSettings?mobileSettings.buttonPositionLeft?`${mobileSettings.buttonPositionLeft}%`:0:0,
    right:mobileSettings?mobileSettings.buttonPositionRight?`${mobileSettings.buttonPositionRight}%`:0:0,
    bottom:mobileSettings?mobileSettings.buttonPositionBottom?`${mobileSettings.buttonPositionBottom}%`:0:0
     }
    },
    image: {
      position: "relative",
      width: "100%",
      height:"auto",
      [theme.breakpoints.down('xs')]:{
        minHeight:'80vh',
        '&>div':{
          minHeight:"80vh"
        }
      },
      '@media(max-width:330px)':{
        minHeight:'100vh',
        '&>div':{
          minHeight:"100vh"
        }
      }

    },
    imageOverlay:{
      position:'absolute',
      top:0,
      left:0,
      width:"100%",
      height:"100%",
      backgroundColor:desktop.bgColor,
      display:desktop.display,
      [theme.breakpoints.down('xs')]:{
        backgroundColor:mobile.bgColor,
        display:mobile.display,
      }
    }
  }))
  const classes = useStyles()
  const imageForSection=phoone?imageMobile?imageMobile:image:image
  return (
    <Container maxWidth="xl" style={{position:"relative"}}>
      <Box mt={phoone ? 6 : 12} mb={phoone ? 6 : 12}>
        <div className={classes.header}>
        {data.heading && (
          <Typography
            variant="h2"
            color={data.headingcolor ? data.headingcolor : "primary"}
          >
            {data.heading}
          </Typography>
        )}
        {data.label && (
          <Typography
            variant="h2"
            color={data.headingcolor ? data.headingcolor : "primary"}
          >
            {data.label}
          </Typography>
        )}</div>
        <div className={classes.image}>
          <Image
            {...imageForSection}
            css={{
              width:"100%"
            }}
            
          />
          {data.imageBG && data.textBG&&(
            <div
              style={{
                backgroundColor: `rgba(${data.textBG.rgb.r},${data.textBG.rgb.g},${data.textBG.rgb.b},${data.textBG.alpha})`,
                position: "absolute",
                width: "100%",
                height: "100%",
                top: 0,
                left: 0,
              }}
            />
          )}
          <div className={classes.text}>
            <TextBlock text={data.text} />
          </div>
          {data.sectionbutton && (
            <div className={classes.button}>
              <Cta data={data.sectionbutton} fullWidth size="small" contained primary/>
            </div>
          )}
        </div>
      </Box>
    </Container>
  )
}
