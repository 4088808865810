import {buildImageObj} from './helpers'
import {imageUrlFor} from './image-url'
import { isNil, join, map, pipe, split } from "ramda"



export default function getFluidImage (
  rawImage,
  fluidImage,
) {
  const url = imageUrlFor(buildImageObj(rawImage)).url()
  const rect = new URL(url).searchParams.get("rect")
  const addRectToUrl = (rect) => (incomingUrl) => {
    if (isNil(rect)) return incomingUrl
    const [url, size] = split(" ")(incomingUrl)
    return `${url}&rect=${rect} ${size}`
  }
  const convertUrl = addRectToUrl(rect)

  const addRectToUrlSet = (rect) => (incomingUrl) =>
    isNil(rect)
      ? incomingUrl
      : pipe(split(","), map(convertUrl), join(","))(incomingUrl)
  const convertUrlSet = addRectToUrlSet(rect)

  return {
    ...fluidImage,
    src: convertUrl(fluidImage.src),
    srcSet: convertUrlSet(fluidImage.srcSet),
    srcSetWebp: convertUrlSet(fluidImage.srcSetWebp),
    srcWebp: convertUrl(fluidImage.srcWebp),
  }
}