import React, { useState } from "react"
import {
  Container,
  Grid,
  Typography,
  makeStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  DialogActions,
} from "@material-ui/core"
import ProductCard from "./product"
import TextBlock from "../../../lib/textblock"
import EnquiryForm from "./enquiryform"
import Alert from "../blocks/alert"

export default function ProductsSection({ data, image, siteEmail }) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    product: "",
  })
  const [showForm, setShowForm] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const onSubmit = e => {
    e.preventDefault()
    const { product, email, phone, name } = formData
    const textpart = `Dear Karaena, ${name} would like to know more about ${product} Please call ${phone} or email ${email}`
    const htmlpart = `<h3>Dear Karaena,</h3><br /> <strong>${name}</strong> would like to know more about <h3>${product}</h3> please call ${phone} or email ${email}`
    const data = {
      authID: "d63a50f1e641bb7fe527eca3a88d5eeb",
      message: { textpart: textpart, htmlpart: htmlpart },
      fromemail: "webform@karaenavincent.co.nz",
      email: siteEmail,
      name: "Karaena Vincent",
    }
    const url = `https://ath.nz/athAPI?action=sendmail&data=${JSON.stringify(
      data
    )}`
    fetch(url)
      .then(data => {
        setShowForm(false)
        data.json().then(m => {
          setShowToast(true)
        })
      })
      .catch(e => {
        setShowForm(false)
      })
  }

  const useStyles = makeStyles(theme => ({
    margins: {
      margin: theme.spacing(12, 0),
      padding: theme.spacing(0, 12),
      [theme.breakpoints.down("xs")]: {
        margin: theme.spacing(6, 0),
        padding: theme.spacing(0, 1),
      },
    },
  }))
  const classes = useStyles()
  const maxItems = data.productsPerRow ? 12 / parseInt(data.productsPerRow) : 4

  return (
    <Container maxWidth="xl" className={classes.margins}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Typography variant="h2" color="primary" align="center">
            {data.listTitle}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextBlock text={data.listDescription} />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3} justify="center">
            {data.products.map((card, index) => (
              <Grid item xs={12} md={maxItems} lg={maxItems} key={index}>
                <ProductCard
                  actions={{ onshow: setShowForm, values: setFormData }}

                  data={card}
                  buttons={data.buttons ? data.buttons : null}
                  image={
                    image.products.image ? image.products[index].image : null
                  }
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Alert message="Message Sent!" setState={setShowToast} open={showToast} />
      <Dialog maxWidth="md" open={showForm} onClose={() => setShowForm(false)}>
        <DialogActions style={{ backgroundColor: "#d69e5a" }}>
          <IconButton
            style={{ height: "36px" }}
            color="secondary"
            onClick={() => setShowForm(false)}
          >
            x
          </IconButton>
        </DialogActions>
                <DialogTitle className={classes.formBG}>Enquiry for <strong>{formData.product}</strong></DialogTitle>
        <DialogContent style={{ padding: "3rem" }}>
          <EnquiryForm values={setFormData} onSubmit={onSubmit} />
        </DialogContent>
      </Dialog>
    </Container>
  )
}
