import React from "react"
import {
  Typography,
  Container,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { Cta } from "../blocks"
import TextBlock from "../../../lib/textblock"
import Image from "gatsby-plugin-sanity-image"
import getFluidImage from "../../../lib/sanitygatsbyimage"

export default function ImageText({ data, image, removecolors }) {
  console.log(image)
  const fluid = getFluidImage(data.image, image.asset.fluid)
  const color = data.headingcolor
    ? `rgba(${data.headingcolor.rgb.r},${data.headingcolor.rgb.g},${data.headingcolor.rgb.b},${data.headingcolor.alpha})`
    : "primary"
  const useStyles = makeStyles(theme => ({
    container: {
      display: "grid",
      position:"relative",
      gridGap: theme.spacing(4),
      gridRowGap: 0,
      minHeight: "0",
      minWidth: "0",

      maxHeight: "min-content",
      gridTemplateColumns: "1fr 1fr",
      gridTemplateRows: "minmax(150px,min-content) 1fr",
      gridTemplateAreas:
        data.imagePosition === "left"
          ? `
                      "image heading"
                      "image textblock"
                      `
          : `
                      "heading image"
                      "textblock image"`,
      [theme.breakpoints.down("sm")]: {
        gridTemplateAreas:
          data.imagePosition === "left"
            ? `
                      "heading heading"
                      "image image"
                      "textblock textblock"
                      `
            : `
            "heading heading"
            "image image"
            "textblock textblock"`,
      },
      [theme.breakpoints.down("xs")]: {
        gridTemplateAreas: `
                      "heading heading"
                      "image image"
                      "textblock textblock"
                      `,
      },
    },
    heading: {
      gridArea: "heading",
      justifySelf: "start",
      alignSelf: "center",
      padding: theme.spacing(2, 4),
      [theme.breakpoints.down("xs")]: {
        padding: 0,
        justifySelf:"center",
      },
    },
    image: {
      gridArea: "image",
      justifySelf: "center",
      alignSelf: "center",
      width: "100%",
      padding: theme.spacing(8),
      minHeight: "0",
      minWidth: "0",
      maxWidth:"100%",
      [theme.breakpoints.down('md')]:{
        width:"100%",
        padding:0
      }
    },
    textblock: {
      gridArea: "textblock",
      height:"100%",
      justifySelf: "start",
      alignSelf: "center",
      padding: theme.spacing(2, 4),
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(2, 0),
      },
    },
    margins: {
      margin: theme.spacing(12, 0),
      [theme.breakpoints.down("xs")]: {
        margin: theme.spacing(6, 0),
      },
    },
  }))
  const classes = useStyles()

  return (
    <Container maxWidth="xl" className={classes.margins}>
      <div className={classes.container}>
        <div className={classes.image}>
          <Image
            {...image}
            
            css={{
              maxWidth:'100%',
              maxHeight:'90vh'
            }}
          />
        </div>
        <div className={classes.textblock}>
          <TextBlock text={data.text} removecolors={removecolors} />
          {data.cta && <Cta data={data.cta} />}
        </div>
        <div className={classes.heading}>
          {data.heading && (
            <>
              <Typography color={color} variant="h2" align="center">
                {data.heading}
              </Typography>
              {data.label && (
                <Typography color={color} variant="h4" align="center">
                  {data.label}
                </Typography>
              )}
            </>
          )}
        </div>
      </div>
    </Container>
  )
}
