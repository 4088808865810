import React from "react"
import { Typography, Container, Grid, Box, Paper } from "@material-ui/core"
import Testimonial from "./testimonial"
import { makeStyles } from "@material-ui/styles"
import Slider from "../../../lib/carousel"
import Slide from "react-reveal/Slide"

const useStyles = makeStyles(theme => ({}))
export default function TestimonialSection({ data,image }) {
  const classes = useStyles()
  return (
    <Container maxWidth="xl">
      <Box mt={12} mb={6}>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12}>
            <Typography
              align="center"
              color={data.headingcolor ? data.headingcolor : "primary"}
              className={classes.heading}
              variant="h2"
            >
              {data.heading}
            </Typography>
            <Typography
              align="center"
              color={data.headingcolor ? data.headingcolor : "primary"}
              className={classes.subtext}
              variant="h4"
            >
              {data.title}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={7} style={{ position: "relative" }}>
            <Paper elevation={6} style={{overflow:"hidden"}}>
              <Slider dots arrows height="fit-content">
                {data.testimonial.map((queue, index) => (
                  <Slide  right key={index} index={index} style={{height:"fit-content"}}>
                    <Testimonial data={queue} image={image[index].companyLogo} />
                  </Slide>
                ))}
              </Slider>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}
