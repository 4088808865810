import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"
import TextBlock from "../../../lib/textblock"
import Image from "gatsby-plugin-sanity-image"

import { Cta } from "../blocks"

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    width: "100%",
    overflow:"visible"
  },
  media: {
    height: "50%",
    overflow: "hidden",
  }
}))

export default function Cards({ card,image }) {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardMedia className={classes.media} title={card.heading}>
        <Image {...image} css={{width:'100%'}}/>
      </CardMedia>
      <CardContent>
        <Typography gutterBottom variant="h4">
          {card.heading}
        </Typography>
        <TextBlock text={card.text} />
      </CardContent>

      <CardActions>
        {card.cta && (
          <Cta data={card.cta} size="small" color="primary"/>
        )}
      </CardActions>
    </Card>
  )
}
