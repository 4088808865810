import React from "react"
import { buildImageObj } from "../../../lib/helpers"
import { imageUrlFor } from "../../../lib/image-url"
import { Typography, ButtonBase, Paper } from "@material-ui/core"
import {makeStyles} from '@material-ui/styles'
import { navigate } from "gatsby"
import slugify from "@sindresorhus/slugify"
const useStyles=makeStyles(theme=>({
  card:{
    position:"relative",
    height:'50vh',
    width:'90vw',
    '@media(min-width:1024px)':{
      width:'30vw',
      height:'30vw'
    },
  },
  text:{
    color:theme.palette.getContrastText(theme.palette.primary.main)
  },
  image: {
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('xs')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.15,
      },
      '& $imageMarked': {
        opacity: 0,
      },
      '& $imageTitle': {
        border: '4px solid currentColor',
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
}))
export default function AthCard({card}) {
    const classes=useStyles();
    const image =

    imageUrlFor(buildImageObj(card.image))
      .width(500)
      .url()

  return (
    <Paper elevation={5} className={classes.card}>
    <ButtonBase focusRipple key={card.image.asset.assetId} className={classes.image} focusVisibleClassName={classes.focusVisible} style={{width:"100%",height:"100%"}} onClick={()=>navigate(slugify(card.cta.route.slug.current))}>
        <span className={classes.imageSrc} style={{backgroundImage:`url(${image})`}}/>
        <span className={classes.imageBackdrop}/>
        <span className={classes.imageButton}>
          <Typography component="span" variant="subtitle1" color="inherit" className={classes.imageTitle}>{card.heading}
          <span className={classes.imageMarked}/>
          </Typography>
        </span>
    </ButtonBase>
    </Paper>
  )
}
