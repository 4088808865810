import React from "react"
import {
  Grid,
  Container,
  Typography,
  Box,
  useMediaQuery,
} from "@material-ui/core"
import TextBlock from "../../../lib/textblock"
import { Cta } from "../blocks"
export default function TextSection({ data }) {
  const phone = useMediaQuery("(max-width:500px)")

  return (
    <Container maxWidth="xl">
      <Box p={phone ? 0 : 2} mt={phone ? 6 : 12} mb={phone ? 6 : 12}>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} lg={10}>

              {data.heading && (
                <Typography
                  color={data.headingcolor ? data.headingcolor : "primary"}
                  variant="h2"
                  align="center"
                >
                  {data.heading}
                </Typography>
              )}
              {data.label && (
                <Typography
                  color={data.headingcolor ? data.headingcolor : "primary"}
                  variant="h4"
                  align="center"
                >
                  {data.label}
                </Typography>
              )}

          </Grid>
          <Grid item xs={12} lg={8}>
           {data.text&&<TextBlock text={data.text} />}
          </Grid>
          <Grid item xs={12} sm={6}>
            {data.sectionbutton && (

                <Cta
                  data={data.sectionbutton}
                  size="large"
                  fullWidth
                  color="primary"
                  variant="contained"
                  style={{ padding: "2rem" }}
                />

            )}
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}
