import React from 'react'
import {TextField,Grid,Button} from '@material-ui/core'
export default function EnquiryForm({values,onSubmit}){
const onChange=(e)=>{
    values(prevVal=>({...prevVal,[e.target.name]:e.target.value}))
}
    return(
        <form noValidate onSubmit={onSubmit}>
        <Grid container spacing={3} >
          <Grid item xs={12}>
            <TextField
              name="name"
              variant="standard"
              label="Name"
              fullWidth
              onChange={e=>onChange(e)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="phone"
              variant="standard"
              label="Phone"
              fullWidth
              onChange={e=>onChange(e)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="email"
              name="email"
              variant="standard"
              label="Email"
              fullWidth
              onChange={e=>onChange(e)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button size="large" fullWidth type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    )
}