import React from "react"
import { Typography, Grid, Container, Box } from "@material-ui/core"
import AthCard from "./cards"
import Cta from '../blocks/cta'

import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles(theme => ({
  margins: {
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(12),
    padding:theme.spacing(0,30),
    [theme.breakpoints.down('lg')]:{
      padding:theme.spacing(0,10)
    },
    [theme.breakpoints.down('md')]:{
      padding:0
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
    },

  },
  grid: {
    justifyContent: "center",
    [theme.breakpoints.between("xs", "md")]: {
      justifyContent: "left",
    },
  },
}))

const InfoCards = ({ data, images }) => {
  const classes = useStyles()
  return (
    <Container maxWidth="xl">
      <Box className={classes.margins}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Typography
              variant="h2"
              align="center"
              color={data.headingcolor ? data.headingcolor : "primary"}
            >
              {data.heading}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container className={classes.grid} spacing={1}>
              {data.cards.map((card, index) => (
                <Grid item key={index} xs={12} sm={4} md={4} lg={4}>
                  <AthCard card={card} image={images.cards[index].image} />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Container maxWidth="sm" style={{ width: "100%" }}>
              <Cta data={data.sectionbutton} color="primary" size="large" fullWidth={true}/>
            </Container>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}
export default InfoCards
