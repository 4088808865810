import React from "react"
import { Parallax } from "react-scroll-parallax"
import { makeStyles, Typography, Grid, Box, useTheme, useMediaQuery } from "@material-ui/core"
import { Cta } from "../blocks"
import Image from "gatsby-plugin-sanity-image"
import TextBlock from "../../../lib/textblock"


export default function Hero({ data, image }) {
  const theme=useTheme()
  const isPhone=useMediaQuery(theme.breakpoints.down('xs'))
  const fluid = !isPhone?image.backgroundImage:image.backgroundImageMobile? image.backgroundImageMobile:image.backgroundImage
  
  const mobileSettings = data.settings
    ? data.settings.mobileProps
      ? data.settings.mobileProps
      : null
    : null
  const desktopSettings = data.settings
    ? data.settings.desktopProps
      ? data.settings.desktopProps
      : null
    : null
    console.log(desktopSettings)
  const desktop = {
    top: desktopSettings
      ? desktopSettings.desktopPositionTop
        ? `${desktopSettings.desktopPositionTop}%`
        : 0
      : 0,
    bottom: desktopSettings
      ? desktopSettings.desktopPositionBottom
        ? `${desktopSettings.desktopPositionBottom}%`
        : 0
      : 0,
    left: desktopSettings
      ? desktopSettings.desktopPositionLeft
        ? `${desktopSettings.desktopPositionLeft}%`
        : 0
      : 0,
    right: desktopSettings
      ? desktopSettings.desktopPositionRight
        ? `${desktopSettings.desktopPositionRight}%`
        : 0
      : 0,
    paddingTop: desktopSettings
      ? desktopSettings.desktopPaddingTop
        ? `${desktopSettings.desktopPaddingTop}px`
        : 0
      : 0,
    paddingBottom: desktopSettings
      ? desktopSettings.desktopPaddingBottom
        ? `${desktopSettings.desktopPaddingBottom}px`
        : 0
      : 0,
    paddingLeft: desktopSettings
      ? desktopSettings.desktopPaddingLeft
        ? `${desktopSettings.desktopPaddingLeft}px`
        : 0
      : 0,
    paddingRight: desktopSettings
      ? desktopSettings.desktopPaddingRight
        ? `${desktopSettings.desktopPaddingRight}px`
        : 0
      : 0,
    marginTop: desktopSettings
      ? desktopSettings.desktopMarginTop
        ? `${desktopSettings.desktopMarginTop}px`
        : 0
      : 0,
    marginBottom: desktopSettings
      ? desktopSettings.desktopMarginBottom
        ? `${desktopSettings.desktopMarginBottom}px`
        : 0
      : 0,
    marginLeft: desktopSettings
      ? desktopSettings.desktopMarginLeft
        ? `${desktopSettings.desktopMarginLeft}px`
        : 0
      : 0,
    marginRight: desktopSettings
      ? desktopSettings.desktopMarginRight
        ? `${desktopSettings.desktopMarginRight}px`
        : 0
      : 0,
    bgColor: desktopSettings
      ? desktopSettings.desktopBGColor
        ? `rgba(${desktopSettings.desktopBGColor.rgb.r},${desktopSettings.desktopBGColor.rgb.g},${desktopSettings.desktopBGColor.rgb.b},${desktopSettings.desktopBGColor.alpha})`
        : "none"
      : "none",
    display: desktopSettings
      ? desktopSettings.bgFullWidthDesktop
        ? "block"
        : "none"
      : "none",
    buttonsPositionTop:desktopSettings?`${desktopSettings.buttonPositionTop}%`:0,
    buttonsPositionLeft:desktopSettings?`${desktopSettings.buttonPositionLeft}%`:0,
    buttonsPositionRight:desktopSettings?`${desktopSettings.buttonPositionRight}%`:0,
    buttonsPositionBottom:desktopSettings?`${desktopSettings.buttonPositionBottom}%`:0,
  }
  const mobile = {
    top: mobileSettings
      ? mobileSettings.mobilePositionTop
        ? `${mobileSettings.mobilePositionTop}%`
        : 0
      : 0,
    bottom: mobileSettings
      ? mobileSettings.mobilePositionBottom
        ? `${mobileSettings.mobilePositionBottom}%`
        : 0
      : 0,
    left: mobileSettings
      ? mobileSettings.mobilePositionLeft
        ? `${mobileSettings.mobilePositionLeft}%`
        : 0
      : 0,
    right: mobileSettings
      ? mobileSettings.mobilePositionRight
        ? `${mobileSettings.mobilePositionRight}%`
        : 0
      : 0,
    paddingTop: mobileSettings
      ? mobileSettings.mobilePaddingTop
        ? `${mobileSettings.mobilePaddingTop}px`
        : 0
      : 0,
    paddingBottom: mobileSettings
      ? mobileSettings.mobilePaddingBottom
        ? `${mobileSettings.mobilePaddingBottom}px`
        : 0
      : 0,
    paddingLeft: mobileSettings
      ? mobileSettings.mobilePaddingLeft
        ? `${mobileSettings.mobilePaddingLeft}px`
        : 0
      : 0,
    paddingRight: mobileSettings
      ? mobileSettings.mobilePaddingRight
        ? `${mobileSettings.mobilePaddingRight}px`
        : 0
      : 0,
    marginTop: mobileSettings
      ? mobileSettings.mobileMarginTop
        ? `${mobileSettings.mobileMarginTop}px`
        : 0
      : 0,
    marginBottom: mobileSettings
      ? mobileSettings.mobileMarginBottom
        ? `${mobileSettings.mobileMarginBottom}px`
        : 0
      : 0,
    marginLeft: mobileSettings
      ? mobileSettings.mobileMarginLeft
        ? `${mobileSettings.mobileMarginLeft}px`
        : 0
      : 0,
    marginRight: mobileSettings
      ? mobileSettings.mobileMarginRight
        ? `${mobileSettings.mobileMarginRight}px`
        : 0
      : 0,
    bgColor: mobileSettings
      ? mobileSettings.mobileBGColor
        ? `${mobileSettings.mobileBGColor.rgb.r},${mobileSettings.mobileBGColor.rgb.g},${mobileSettings.mobileBGColor.rgb.b})`
        : "none"
      : "none",
    display: mobileSettings
      ? mobileSettings.bgFullWidthDesktop
        ? "block"
        : "none"
      : "none",
      buttonsPositionTop:mobileSettings?`${mobileSettings.buttonPositionTop}%`:0,
      buttonsPositionLeft:mobileSettings?`${mobileSettings.buttonPositionLeft}%`:0,
      buttonsPositionRight:mobileSettings?`${mobileSettings.buttonPositionRight}%`:0,
      buttonsPositionBottom:mobileSettings?`${mobileSettings.buttonPositionBottom}%`:0,
  }
  const useStyles = makeStyles(theme => ({
    gridBackgronud: {
      padding: theme.spacing(2),
      gridArea: "tagline",
      background:
        desktop.bgColor !== "none"
          ? `linear-gradient(90deg, rgba(${desktop.bgColor},0) 0%, rgba(${desktop.bgColor},1) 50%, rgba(${desktop.bgColor},0) 100%)`
          : "linear-gradient(90deg, rgba(214,158,90,0) 0%, rgba(214,158,90,1) 50%, rgba(214,158,90,0) 100%)",
      [theme.breakpoints.down("xs")]: {
        background:
          mobile.bgColor !== "none"
            ? `linear-gradient(90deg, rgba(${mobile.bgColor},0) 0%, rgba(${mobile.bgColor},1) 50%, rgba(${mobile.bgColor},0) 100%)`
            : "linear-gradient(90deg, rgba(214,158,90,0) 0%, rgba(214,158,90,1) 50%, rgba(214,158,90,0) 100%)",
      },
    },
    buttonStyle: {
      padding: "5% 15%",
    },
    textContainer: {
      position: "relative",
      height: "100%",
      width: "100%",
    },
    textabsolute: {
      position: "absolute",
      top: desktop.top,
      left: desktop.left,
      right: desktop.right,
      bottom: desktop.bottom,
      padding: `${desktop.paddingTop} ${desktop.paddingRight} ${desktop.paddingBottom} ${desktop.paddingLeft}`,
      margin: `${desktop.marginTop} ${desktop.marginRight} ${desktop.marginBottom} ${desktop.marginLeft}`,
      [theme.breakpoints.down("xs")]: {
        top: mobile.top,
        left: mobile.left,
        right: mobile.right,
        bottom: mobile.bottom,
        padding: `${mobile.paddingTop} ${mobile.paddingRight} ${mobile.paddingBottom} ${mobile.paddingLeft}`,
        margin: `${mobile.marginTop} ${mobile.marginRight} ${mobile.marginBottom} ${mobile.marginLeft}`,
      },
    },
    margins: {
      margin: theme.spacing(0, 0, 12, 0),
      [theme.breakpoints.down("xs")]: {
        margin: theme.spacing(0, 0, 6, 0),
      },
    },
    image: {

        height: "100%",

    },
    gridButtons:{
      position:'absolute',
      top:desktop.buttonsPositionTop,
      left:desktop.buttonsPositionLeft,
      right:desktop.buttonsPositionRight,
      bottom:desktop.buttonPositionBottom,
      [theme.breakpoints.down('sm')]:{
        top:mobile.buttonsPositionTop,
        left:mobile.buttonsPositionLeft,
        right:mobile.buttonsPositionRight,
        bottom:mobile.buttonPositionBottom
      }
    }
  }))
  const classes = useStyles()
  return (
    <Box
      className={classes.margins}
      style={{ overflow: "hidden", position: "relative", height: "100vh" }}
    >
      <Parallax
        y={[-50, 50]}
        styleInner={{ height: "100%" }}
        styleOuter={{ height: "100%" }}
      >
        <Image
         {...fluid}
         css={{
           width:'100%'
         }}
        />
      </Parallax>

      <div className={classes.textabsolute}>
        <div style={{ textAlign: "center" }}>
          <Grid container justify="center">
            {data.tagline&&data.heading&&<Grid item xs={12} className={classes.gridBackgronud}>
              <Typography
                className={classes.gridHeader}
                variant="h1"
                color={data.headingcolor ? data.headingcolor : "textPrimary"}
              >
                {data.heading}
              </Typography>
              <TextBlock text={data.tagline} />
            </Grid>}
            <Grid item xs={12}>
              <div className={classes.gridButtons}>
                <Grid container direction="column" justify="center" spacing={3}>
                  {data.ctas
                    ? data.ctas.map((cta, index) => (
                        <Grid item xs={12} key={index}>

                            <Cta fullWidth size="large" data={cta} />

                        </Grid>
                      ))
                    : ""}
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </Box>
  )
}
