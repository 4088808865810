import React from "react"
import {
  Typography,
  Grid,
  Container,
  Box,
  useMediaQuery,
} from "@material-ui/core"
import { AthCard } from "../blocks"

import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles({
})

const Projects = ({ data }) => {
  const classes = useStyles()
  const phone = useMediaQuery("max-width:500px")
  return (
    <Container maxWidth="xl">
      <Box mt={phone ? 6 : 12} mb={phone ? 6 : 12}>
        <Grid container >
          <Grid item xs={12}>

              <Typography
                className={classes.header}
                variant="h2"
                align="center"
                color={data.headingcolor ? data.headingcolor : "primary"}
              >
                {data.heading}
              </Typography>

          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center" spacing={3}>
              {data.cards.map((card, index) => (
                <Grid item key={index}>
                  {" "}
                  <AthCard card={card} />{" "}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}
export default Projects
